import * as React from "react"
import { Pieces, Button, AnFadeIn } from "components"
import { graphql } from "gatsby"
import {
  hero,
  top,
  bottom,
  carousel,
  carouselImage,
  titleSection,
  clientsSection,
  clientsList,
  buttonClass,
  carouselImageTitle,
  carouselItem,
} from "./styles.module.css"
import { homeMobile } from "images"
import { RichText } from "prismic-reactjs"
import { Swiper, SwiperSlide } from "swiper/react"
import { navigate } from "gatsby" //import navigate from gatsby

const IndexPage = ({ data }) => {
  const {
    carousel_images,
    client_images,
    clients_title,
    other_clients,
    title,
    title_bottom,
    title_text,
    title_top,
  } = data.allPrismicLanding.nodes[0].data

  return (
    <AnFadeIn>
      <div className={hero} style={{ backgroundImage: `url(${homeMobile})` }}>
        <Pieces />
        <h1 className={top}>{title_top.text}</h1>
        <h1 className={bottom}>{title_bottom.text}</h1>
      </div>
      <section className={titleSection}>
        <div>
          <h2>{title.text}</h2>
        </div>
        <div>
          <RichText render={title_text.raw} />
        </div>
      </section>
      <section className={carousel}>
        <Swiper
          slidesPerView={1}
          speed={1500}
          lazy={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            800: {
              slidesPerView: 3,
            },
          }}
        >
          {[
            ...carousel_images,
            ...carousel_images,
            ...carousel_images,
            ...carousel_images,
            ...carousel_images,
            ...carousel_images,
            ...carousel_images,
          ].map((image, index) => (
            <SwiperSlide
              key={`$imageCarousel-${index}`}
              className={carouselItem}
            >
              <img
                className={carouselImage}
                src={image.carousel_image.url}
                alt={image.carousel_image.alt}
              />
              <h4 className={carouselImageTitle}>{image.carousel_image.alt}</h4>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      <section className={clientsSection}>
        <h3>{clients_title.text}</h3>
        <ul className={clientsList}>
          {client_images.map(image => {
            return (
              <li key={image.client_logo.url}>
                <img src={image.client_logo.url} alt={image.client_logo.alt} />
              </li>
            )
          })}
        </ul>
        <p>Along with</p>
        <h4>{other_clients.text}</h4>

        <Button
          onClick={() => navigate("/journal")}
          className={buttonClass}
          title={"SEE OUR WORK"}
        />
      </section>
    </AnFadeIn>
  )
}

export const query = graphql`
  query LandingPage {
    allPrismicLanding {
      nodes {
        data {
          carousel_images {
            carousel_image {
              url
              alt
            }
          }
          client_images {
            client_logo {
              alt
              url
            }
          }
          clients_title {
            text
          }
          other_clients {
            text
          }
          title {
            text
          }
          title_bottom {
            text
          }
          title_text {
            raw
          }
          title_top {
            text
          }
        }
      }
    }
  }
`

export default IndexPage
