// extracted by mini-css-extract-plugin
export var hero = "styles-module--hero--2alus";
export var top = "styles-module--top--1OQmc";
export var bottom = "styles-module--bottom--3K1Mr";
export var fadeIn = "styles-module--fadeIn--3pAOx";
export var titleSection = "styles-module--titleSection--15__o";
export var carousel = "styles-module--carousel--2zytK";
export var growIn = "styles-module--growIn--1Flgq";
export var carouselImage = "styles-module--carouselImage--3AN21";
export var carouselImageTitle = "styles-module--carouselImageTitle--3Xu85";
export var carouselItem = "styles-module--carouselItem--1LatP";
export var clientsSection = "styles-module--clientsSection--tY9CO";
export var clientsList = "styles-module--clientsList--3RJb3";
export var buttonClass = "styles-module--buttonClass--2A-Ng";
export var notFoundTitle = "styles-module--notFoundTitle--1_hJS";
export var notFoundWrapper = "styles-module--notFoundWrapper--24WQ6";
export var mainSection = "styles-module--mainSection--1vF7c";
export var pageContent = "styles-module--pageContent--1V-O-";